import './_vendor';
import vars from './_vars';
import './_functions';
import './_components';
window.onresize = function(event)
{
  getScale();
};
getScale();

function getScale()
{

  let original = {
    phone: 551,
    tablet: 1024,
    desktop: 1024
  };

  var vh = window["innerHeight"] * .01;
  var width = $(window).width();
  // alert(width);
  var scale = {
      phone: width * 19 / original["phone"],
      tablet: width * 23 / original["tablet"],
      desktop: width * 25 / original["desktop"]
  };
  if (width < original["phone"])
  {
      document["documentElement"]["style"].setProperty("font-size", scale["phone"] + "px");
      document["documentElement"]["style"].setProperty("--vh", vh + "px");
  }else
  if (width < original["tablet"])
  {
      document["documentElement"]["style"].setProperty("font-size", scale["tablet"] + "px");
      document["documentElement"]["style"].setProperty("--vh", vh + "px");
  }
  else
  {
      document["documentElement"]["style"].removeProperty("--vh");
      // document["documentElement"]["style"].removeProperty("font-size");
      if (width > 1024 && width <= original["desktop"])
      {
          document["documentElement"]["style"].setProperty("font-size", scale["desktop"] + "px");
      }
      else
      {
          document["documentElement"]["style"].removeProperty("font-size");
      }
  }
};

$('.toggle-menu').click (function(){
  $(this).toggleClass('active');
  $('#menu').toggleClass('open');
  let tgMenu = document.querySelector ('#menu');
document.body.style.overflow = '';
console.log (tgMenu);
if (tgMenu.classList.contains ('open')) {
  document.body.style.overflow = 'hidden';
} else {
  console.log ('');
}
});

function tabPoem () {
  const tabs = document.querySelectorAll ('.gen-kts__itemg'),
          tabsContent = document.querySelectorAll ('.poem__block'),
          tabsParent = document.querySelector ('.portfolio-content-tab__row');
    console.log (tabsContent);
    function hideTabContent () {
        tabsContent.forEach (i => {
            // i.classList.add ('hide');
            // i.classList.remove ('show');
            i.style.display = 'none';
        });

        tabs.forEach (i => {
            i.classList.remove ('tabheader__item_active');
        });
    }

    function showTabContent (i = 0) {
        // tabsContent[i].classList.add ('show');
        // tabsContent[i].classList.remove ('hide');
        tabsContent[i].style.display = 'block';
        tabs[i].classList.add ('tabheader__item_active');
    }

    hideTabContent ();
    // showTabContent ();

    tabs.forEach ((item, num) => {
      item.addEventListener ('click', () => {
        console.log ('sadasd');
        hideTabContent ();
        showTabContent (num);
      })
    })
}
try {
  tabPoem ();
} catch {
  console.log ('')
}
function tabPoem2 () {
  const tabs = document.querySelectorAll ('.gen-kts__itemg123'),
          tabsContent = document.querySelectorAll ('.poem__block123'),
          tabsParent = document.querySelector ('.portfolio-content-tab__row');
    console.log (tabsContent);
    function hideTabContent () {
        tabsContent.forEach (i => {
            // i.classList.add ('hide');
            // i.classList.remove ('show');
            i.style.display = 'none';
        });

        tabs.forEach (i => {
            i.classList.remove ('tabheader__item_active');
        });
    }

    function showTabContent (i = 0) {
        // tabsContent[i].classList.add ('show');
        // tabsContent[i].classList.remove ('hide');
        tabsContent[i].style.display = 'block';
        tabs[i].classList.add ('tabheader__item_active');
    }

    hideTabContent ();
    // showTabContent ();

    tabs.forEach ((item, num) => {
      item.addEventListener ('click', () => {
        console.log ('sadasd');
        hideTabContent ();
        showTabContent (num);
      })
    })
}
try {
  tabPoem2 ();
} catch {
  console.log ('')
}

$(document).ready(function(){
  /**
   * При прокрутке страницы, показываем или срываем кнопку
   */
  $(window).scroll(function () {
      // Если отступ сверху больше 50px то показываем кнопку "Наверх"
      if ($(this).scrollTop() > 50) {
          $('#button-up').fadeIn();
      } else {
          $('#button-up').fadeOut();
      }
  });

  /** При нажатии на кнопку мы перемещаемся к началу страницы */
  $('#button-up').click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
  });

});
function nameFunct() {
  var hScroll = 0;
  var hWindow = jQuery(window).height();
  const headr = document.querySelector ('.header');
  jQuery(window).scroll(function() {
      hScroll = jQuery(this).scrollTop() + hWindow;

      if (jQuery(this).scrollTop() >= 220) {
        headr.classList.add ('head-bg');
      } else {
        headr.classList.remove ('head-bg');
      }
  });
}
nameFunct();

function sliderFilm () {
   new Swiper(".mySwiper61", {
    grabCursor: true,
    effect: "creative",
    navigation: {
      nextEl: '.swiper-button-next1',
      prevEl: '.swiper-button-prev1',
    },
    creativeEffect: {
      prev: {
        shadow: true,
        origin: "left center",
        translate: ["-5%", 0, -200],
        rotate: [0, 100, 0],
      },
      next: {
        origin: "right center",
        translate: ["5%", 0, -200],
        rotate: [0, -100, 0],
      },
    },
  });
}
try {
  sliderFilm ();
} catch {
  console.log ('')
}
function sliderFilm2 () {
  new Swiper(".mySwiper62", {
   grabCursor: true,
   effect: "creative",
   navigation: {
     nextEl: '.swiper-button-next2',
     prevEl: '.swiper-button-prev2',
   },
   creativeEffect: {
     prev: {
       shadow: true,
       origin: "left center",
       translate: ["-5%", 0, -200],
       rotate: [0, 100, 0],
     },
     next: {
       origin: "right center",
       translate: ["5%", 0, -200],
       rotate: [0, -100, 0],
     },
   },
 });
}
try {
 sliderFilm2 ();
} catch {
 console.log ('')
}
function sliderFilm3 () {
  new Swiper(".mySwiper63", {
   grabCursor: true,
   effect: "creative",
   navigation: {
     nextEl: '.swiper-button-next3',
     prevEl: '.swiper-button-prev3',
   },
   creativeEffect: {
     prev: {
       shadow: true,
       origin: "left center",
       translate: ["-5%", 0, -200],
       rotate: [0, 100, 0],
     },
     next: {
       origin: "right center",
       translate: ["5%", 0, -200],
       rotate: [0, -100, 0],
     },
   },
 });
}
try {
 sliderFilm3 ();
} catch {
 console.log ('')
}
function sliderFilm4 () {
  new Swiper(".mySwiper64", {
   grabCursor: true,
   effect: "creative",
   navigation: {
     nextEl: '.swiper-button-next4',
     prevEl: '.swiper-button-prev4',
   },
   creativeEffect: {
     prev: {
       shadow: true,
       origin: "left center",
       translate: ["-5%", 0, -200],
       rotate: [0, 100, 0],
     },
     next: {
       origin: "right center",
       translate: ["5%", 0, -200],
       rotate: [0, -100, 0],
     },
   },
 });
}
try {
 sliderFilm4 ();
} catch {
 console.log ('')
}

function modalForm2 (open2, close, modal) {
  let btnOpen2 = document.querySelectorAll (open2),
        modalBlock = document.querySelector (modal),
        btnClose = document.querySelector (close);

   function openModal (i) {
      modalBlock.classList.remove ('hid');
      document.body.style.overflow = 'hidden';
   }

   function closeModal (i) {
      modalBlock.classList.add ('hid');
      document.body.style.overflow = '';
   }

   console.log (btnOpen2);
   btnOpen2.forEach (item => {
     item.addEventListener ('click', (e) => {
       openModal ();
     })
   })
   console.log (btnOpen2);

   btnClose.addEventListener ('click', () => {
    closeModal ();
  });

   modalBlock.addEventListener ('click', (e) => {
       if (e.target === modalBlock) {
          closeModal ();
       }
   })

   document.addEventListener ('keydown', (e) => {
      if (e.code === 'Escape' ) {
          closeModal ();
      }
  });

}

try {
  modalForm2 (".md-op", ".modal-block-1__close svg", ".modal-block-1");
} catch {
  console.log ('err');
}
